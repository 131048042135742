body {
    height: 100vh;
    background-color: $dark;
    font-family: ubuntu;
    color: $dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300; }
.rl {
    background-color: $rch;
    width: $logo-cirle;
    height: $logo-cirle;
    border-radius: 50%;
    position: relative;
    margin: $logo-cirle * 2;
    z-index: auto;
    &::before {
        box-sizing: content-box;
        content: "";
        background-color: $rch;
        width: $logo-bar - .05rem;
        height: $logo-cirle * 2;
        display: block;
        position: absolute;
        border-style: solid;
        border-color: $logo-bgcolor;
        border-top-width: $logo-cirle * 2;
        border-left-width: $logo-cirle;
        border-bottom-width: $logo-cirle;
        border-right-width: $logo-cirle / 1.7;
        right: $logo-cirle / 2;
        top: $logo-cirle * -2;
        z-index: -1; }
    &::after {
        box-sizing: content-box;
        content: "";
        background-color: $lrsn;
        width: $logo-bar;
        height: $logo-cirle * 3;
        display: block;
        position: absolute;
        border-style: solid;
        border-color: $logo-bgcolor;
        border-top-width: $logo-cirle;
        border-left-width: $logo-cirle / 1.7;
        border-bottom-width: $logo-cirle;
        border-right-width: $logo-cirle;
        top: $logo-cirle * -2;
        left: $logo-cirle / 2;
        z-index: -1; } }
h1,
.logo {
    font-size: 7vw;
    @include breakpoint(phablet) {
        font-size: 6vw; }
    @include breakpoint(tablet) {
        font-size: 5vw; }
    @include breakpoint(laptop) {
        font-size: 4vw; }
    @include breakpoint(desktop) {
        font-size: 3vw; }
    span {
        letter-spacing: -.04rem;
        line-height: 0;
        &:first-of-type {
            font-size: 101.4%;
            font-weight: 700; }
        &:nth-of-type(n+1) {
            color: $rch; }
        &:nth-of-type(4) {
            color: $white; }
        &:nth-of-type(n+5) {
            color: $lrsn; }
        &:nth-of-type(2),
        &:nth-of-type(6),
        &:nth-of-type(8) {
            font-size: 132.3%;
            font-weight: 500; }
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(7) {
            font-weight: 700; } } }
.logo {
    font-size: 1.25rem;
    margin-bottom: .5rem;
    span {
        &:nth-of-type(4) {
            color: $dark; } } }
.legal {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 1rem;
    a {
        text-decoration: none;
        color: transparentize($white, .2);
        transition: color .5s;
        margin: 1rem;
        &:hover {
            color: $white; } } }
section {
    position: absolute;
    padding: 4rem 1rem;
    background-color: $white;
    //align-self: flex-start
    top: 1rem;
    //margin-top: 1rem
    width: 100%;
    //min-height: 100%
    color: $dark;
    transition: opacity .5s;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    // text-align: right
    @include breakpoint(phablet) {
        width: 75%;
        //min-height: 75%
        padding: 4rem 2rem; }
    @include breakpoint(tablet) {
        width: 67%;
        //min-height: 67%
        padding: 3rem; }
    @include breakpoint(laptop) {
        width: 66%;
        //min-height: 66%
        padding: 3.5rem; }
    @include breakpoint(desktop) {
        width: 50%;
        //min-height: 50%
        padding: 4rem; }
    &#imprint {
        top: auto; }

    h1 {
        font-size: 2rem;
        color: $lrsn;
        font-weight: 300;
        margin-bottom: 1rem; }
    h2 {
        font-size: 1.5rem;
        color: $lrsn;
        font-weight: 300;
        margin-bottom: .5rem; }
    h3 {
        font-size: 1.25rem;
        color: $lrsn;
        font-weight: 300;
        margin-bottom: .5rem; }
    p {
        line-height: 1.5;
        margin-bottom: 1rem; }
    a {
        // border: none
        // background-color: transparent
        // cursor: pointer
        text-decoration: none;
        color: $lrsn;
        transition: color .5s;
        &::before {
            content: ' → '; }
        &:hover {
            color: $rch; }
        &.close {
            display: block;
            font-weight: 300;
            color: $rch;
            font-size: 3rem;
            line-height: 1;
            text-decoration: none;
            transition: color .5s;
            position: absolute;
            top: .5rem;
            right: .75rem;
            &::before {
                content: none; }
            @include breakpoint(phablet);

            @include breakpoint(tablet);

            @include breakpoint(laptop);

            @include breakpoint(desktop) {
                right: 1rem; }
            &:hover {
                color: darken($rch, 20%); } } } }
#privacy {
    &:target {
        transition: opacity .5s;
        pointer-events: auto;
        visibility: visible;
        opacity: 1;
        height: auto;
 } }        // display: block
#imprint {
    &:target {
        transition: opacity .5s;
        pointer-events: auto;
        visibility: visible;
        opacity: 1;
        height: auto;
 } }        // display: block
