#video-placeholder {
    @media (min-aspect-ratio: 16/9) {
        height: 100vh;
        width: 177.7777777778vh;
        margin: auto; }
    @media (max-aspect-ratio: 16/9) {
        width: 100vw;
        height: 56.25vw; } }
#player {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        #controls {
            bottom: 0; }
        // #bigplay
        //  visibility: visible
 }        //  opacity: 1
    // #bigplay
    //  width: 5rem
    //  height: 5rem
    //  border-radius: 50%
    //  background-color: $rch
    //  position: absolute
    //  cursor: pointer
    //  visibility: hidden
    //  opacity: 0
    //  transition: opacity .5s
    //  &::before
    //      // content: '❚❚'
    //      content: '▶︎'
    //      color: $white
    //      font-size: 2rem
    //      line-height: 2rem
    //      display: block
    //      position: absolute
    //      top: 50%
    //      left: 50%
    //      transform: translate(-50%,-50%)
    #controls {
        // text-align: right
        color: $white;
        transition: bottom .25s;
        position: absolute;
        bottom: -2.75rem;
        left: 0;
        right: 0;
        width: 100%;
        height: 3rem;
        background-color: transparentize($dark, .15);
        display: flex;
        flex-wrap: wrap;

        #progress-bar {
            align-items: center;
            appearance: none;
            background: none;
            cursor: pointer;
            display: flex;
            height: .25rem;
            // min-height: 50px
            overflow: hidden;
            width: 100%;
            &:focus {
                box-shadow: none;
                outline: none; }
            &::-webkit-slider-runnable-track {
                background: $lrsn;
                content: '';
                height: .25rem;
                pointer-events: none; }
            &::-webkit-slider-thumb {
                width: .25rem;
                height: .25rem;
                appearance: none;
                background: $rch;
                border-radius: none;
                box-shadow: -4000px 0 0 4000px $rch;
                margin-top: 0;
                border: none; }
            &::-moz-range-track {
                width: 100%;
                height: .25rem;
                background: $lrsn; }
            &::-moz-range-thumb {
                width: .25rem;
                height: .25rem;
                background: $rch;
                border-radius: 0;
                border: none;
                position: relative; }
            &::-moz-range-progress {
                height: .25rem;
                background: $rch;
                border: 0;
                margin-top: 0; }
            &::-ms-track {
                background: transparent;
                border: 0;
                border-color: transparent;
                border-radius: 0;
                border-width: 0;
                color: transparent;
                height: .25rem;
                margin-top: 10px;
                width: .25rem; }

            &::-ms-thumb {
                width: .25rem;
                height: .25rem;

                background: $rch;
                border-radius: 0;
                border: none; }

            &::-ms-fill-lower {
                background: $lrsn;
                border-radius: 0; }

            &::-ms-fill-upper {
                background: $rch;
                border-radius: 0; }

            &::-ms-tooltip {
                display: none; } }
        #current-time {
            display: inline-block;
            margin: auto 0 auto auto; }
        #divider {
            display: inline-block;
            margin: auto .25rem; }
        #duration {
            display: inline-block;
            margin: auto .75rem auto 0; }
        #play {
            // display: none
            cursor: pointer;
            width: 2.75rem;
            height: 2.75rem;
            line-height: 2.75rem;
            text-align: center;
            &::before {
                content: '▶︎'; }
            &:hover {
                color: $rch; } }
        #pause {
            // display: none
            cursor: pointer;
            width: 2.75rem;
            height: 2.75rem;
            line-height: 2.75rem;
            text-align: center;
            &::before {
                content: '❚❚'; }
            &:hover {
                color: $rch; } } } }
