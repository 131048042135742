@mixin breakpoint($device) {
    @if $device == desktop {
        @media (min-width: 1440px) {
            @content; } }
    @else if $device == laptop {
        @media (min-width: 1200px) {
            @content; } }
    @else if $device == tablet {
        @media (min-width: 1024px) {
            @content; } }
    @else if $device == phablet {
        @media (min-width: 640px) {
            @content; } }
    @else if $device == mobile {
        @media screen and (max-width: 414px) {
            @content; } } }

$midnightBlue: #191970;
$mediumVioletRed: #c71585;
$mediumTurquoise: #48d1cc;
// $rch: #ff4080
$rch: #F03C69;
// $lrsn: #4080ff
$lrsn: #1C86F2;
// $dark: #003
$dark: #303064;
$white: #fefefe;

$logo-cirle: 1rem;
$logo-bar: .95rem;
$logo-bgcolor: none;

$fontpath: '/fonts/';

@import 'fonts/ubuntu-latin';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0; }
