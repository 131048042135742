/* latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 300;
    src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'), url($fontpath + '4iCp6KVjbNBYlgoKejZftVyPN4FNgYUJ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src: local('Ubuntu Italic'), local('Ubuntu-Italic'), url($fontpath + '4iCu6KVjbNBYlgoKej70l0miFYxn.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 500;
    src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'), url($fontpath + '4iCp6KVjbNBYlgoKejYHtFyPN4FNgYUJ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 700;
    src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'), url($fontpath + '4iCp6KVjbNBYlgoKejZPslyPN4FNgYUJ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: local('Ubuntu Light'), local('Ubuntu-Light'), url($fontpath + '4iCv6KVjbNBYlgoC1CzjsGyNPYZvgw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url($fontpath + '4iCs6KVjbNBYlgoKfw72nU6AFw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url($fontpath + '4iCv6KVjbNBYlgoCjC3jsGyNPYZvgw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url($fontpath + '4iCv6KVjbNBYlgoCxCvjsGyNPYZvgw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
