@charset "UTF-8";
/* latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: local("Ubuntu Light Italic"), local("Ubuntu-LightItalic"), url("/fonts/4iCp6KVjbNBYlgoKejZftVyPN4FNgYUJ.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: local("Ubuntu Italic"), local("Ubuntu-Italic"), url("/fonts/4iCu6KVjbNBYlgoKej70l0miFYxn.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: local("Ubuntu Medium Italic"), local("Ubuntu-MediumItalic"), url("/fonts/4iCp6KVjbNBYlgoKejYHtFyPN4FNgYUJ.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: local("Ubuntu Bold Italic"), local("Ubuntu-BoldItalic"), url("/fonts/4iCp6KVjbNBYlgoKejZPslyPN4FNgYUJ.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: local("Ubuntu Light"), local("Ubuntu-Light"), url("/fonts/4iCv6KVjbNBYlgoC1CzjsGyNPYZvgw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local("Ubuntu Regular"), local("Ubuntu-Regular"), url("/fonts/4iCs6KVjbNBYlgoKfw72nU6AFw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: local("Ubuntu Medium"), local("Ubuntu-Medium"), url("/fonts/4iCv6KVjbNBYlgoCjC3jsGyNPYZvgw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local("Ubuntu Bold"), local("Ubuntu-Bold"), url("/fonts/4iCv6KVjbNBYlgoCxCvjsGyNPYZvgw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  height: 100vh;
  background-color: #303064;
  font-family: ubuntu;
  color: #303064;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 300; }

.rl {
  background-color: #F03C69;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: relative;
  margin: 2rem;
  z-index: auto; }
  .rl::before {
    box-sizing: content-box;
    content: "";
    background-color: #F03C69;
    width: 0.9rem;
    height: 2rem;
    display: block;
    position: absolute;
    border-style: solid;
    border-color: none;
    border-top-width: 2rem;
    border-left-width: 1rem;
    border-bottom-width: 1rem;
    border-right-width: 0.58824rem;
    right: 0.5rem;
    top: -2rem;
    z-index: -1; }
  .rl::after {
    box-sizing: content-box;
    content: "";
    background-color: #1C86F2;
    width: 0.95rem;
    height: 3rem;
    display: block;
    position: absolute;
    border-style: solid;
    border-color: none;
    border-top-width: 1rem;
    border-left-width: 0.58824rem;
    border-bottom-width: 1rem;
    border-right-width: 1rem;
    top: -2rem;
    left: 0.5rem;
    z-index: -1; }

h1,
.logo {
  font-size: 7vw; }
  @media (min-width: 640px) {
    h1,
    .logo {
      font-size: 6vw; } }
  @media (min-width: 1024px) {
    h1,
    .logo {
      font-size: 5vw; } }
  @media (min-width: 1200px) {
    h1,
    .logo {
      font-size: 4vw; } }
  @media (min-width: 1440px) {
    h1,
    .logo {
      font-size: 3vw; } }
  h1 span,
  .logo span {
    letter-spacing: -.04rem;
    line-height: 0; }
    h1 span:first-of-type,
    .logo span:first-of-type {
      font-size: 101.4%;
      font-weight: 700; }
    h1 span:nth-of-type(n+1),
    .logo span:nth-of-type(n+1) {
      color: #F03C69; }
    h1 span:nth-of-type(4),
    .logo span:nth-of-type(4) {
      color: #fefefe; }
    h1 span:nth-of-type(n+5),
    .logo span:nth-of-type(n+5) {
      color: #1C86F2; }
    h1 span:nth-of-type(2), h1 span:nth-of-type(6), h1 span:nth-of-type(8),
    .logo span:nth-of-type(2),
    .logo span:nth-of-type(6),
    .logo span:nth-of-type(8) {
      font-size: 132.3%;
      font-weight: 500; }
    h1 span:nth-of-type(3), h1 span:nth-of-type(4), h1 span:nth-of-type(5), h1 span:nth-of-type(7),
    .logo span:nth-of-type(3),
    .logo span:nth-of-type(4),
    .logo span:nth-of-type(5),
    .logo span:nth-of-type(7) {
      font-weight: 700; }

.logo {
  font-size: 1.25rem;
  margin-bottom: .5rem; }
  .logo span:nth-of-type(4) {
    color: #303064; }

.legal {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 1rem; }
  .legal a {
    text-decoration: none;
    color: rgba(254, 254, 254, 0.8);
    transition: color .5s;
    margin: 1rem; }
    .legal a:hover {
      color: #fefefe; }

section {
  position: absolute;
  padding: 4rem 1rem;
  background-color: #fefefe;
  top: 1rem;
  width: 100%;
  color: #303064;
  transition: opacity .5s;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden; }
  @media (min-width: 640px) {
    section {
      width: 75%;
      padding: 4rem 2rem; } }
  @media (min-width: 1024px) {
    section {
      width: 67%;
      padding: 3rem; } }
  @media (min-width: 1200px) {
    section {
      width: 66%;
      padding: 3.5rem; } }
  @media (min-width: 1440px) {
    section {
      width: 50%;
      padding: 4rem; } }
  section#imprint {
    top: auto; }
  section h1 {
    font-size: 2rem;
    color: #1C86F2;
    font-weight: 300;
    margin-bottom: 1rem; }
  section h2 {
    font-size: 1.5rem;
    color: #1C86F2;
    font-weight: 300;
    margin-bottom: .5rem; }
  section h3 {
    font-size: 1.25rem;
    color: #1C86F2;
    font-weight: 300;
    margin-bottom: .5rem; }
  section p {
    line-height: 1.5;
    margin-bottom: 1rem; }
  section a {
    text-decoration: none;
    color: #1C86F2;
    transition: color .5s; }
    section a::before {
      content: ' → '; }
    section a:hover {
      color: #F03C69; }
    section a.close {
      display: block;
      font-weight: 300;
      color: #F03C69;
      font-size: 3rem;
      line-height: 1;
      text-decoration: none;
      transition: color .5s;
      position: absolute;
      top: .5rem;
      right: .75rem; }
      section a.close::before {
        content: none; }
      @media (min-width: 1440px) {
        section a.close {
          right: 1rem; } }
      section a.close:hover {
        color: #b80e39; }

#privacy:target {
  transition: opacity .5s;
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  height: auto; }

#imprint:target {
  transition: opacity .5s;
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  height: auto; }

@media (min-aspect-ratio: 16 / 9) {
  #video-placeholder {
    height: 100vh;
    width: 177.7777777778vh;
    margin: auto; } }

@media (max-aspect-ratio: 16 / 9) {
  #video-placeholder {
    width: 100vw;
    height: 56.25vw; } }

#player {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  #player:hover #controls {
    bottom: 0; }
  #player #controls {
    color: #fefefe;
    transition: bottom .25s;
    position: absolute;
    bottom: -2.75rem;
    left: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    background-color: rgba(48, 48, 100, 0.85);
    display: flex;
    flex-wrap: wrap; }
    #player #controls #progress-bar {
      align-items: center;
      appearance: none;
      background: none;
      cursor: pointer;
      display: flex;
      height: .25rem;
      overflow: hidden;
      width: 100%; }
      #player #controls #progress-bar:focus {
        box-shadow: none;
        outline: none; }
      #player #controls #progress-bar::-webkit-slider-runnable-track {
        background: #1C86F2;
        content: '';
        height: .25rem;
        pointer-events: none; }
      #player #controls #progress-bar::-webkit-slider-thumb {
        width: .25rem;
        height: .25rem;
        appearance: none;
        background: #F03C69;
        border-radius: none;
        box-shadow: -4000px 0 0 4000px #F03C69;
        margin-top: 0;
        border: none; }
      #player #controls #progress-bar::-moz-range-track {
        width: 100%;
        height: .25rem;
        background: #1C86F2; }
      #player #controls #progress-bar::-moz-range-thumb {
        width: .25rem;
        height: .25rem;
        background: #F03C69;
        border-radius: 0;
        border: none;
        position: relative; }
      #player #controls #progress-bar::-moz-range-progress {
        height: .25rem;
        background: #F03C69;
        border: 0;
        margin-top: 0; }
      #player #controls #progress-bar::-ms-track {
        background: transparent;
        border: 0;
        border-color: transparent;
        border-radius: 0;
        border-width: 0;
        color: transparent;
        height: .25rem;
        margin-top: 10px;
        width: .25rem; }
      #player #controls #progress-bar::-ms-thumb {
        width: .25rem;
        height: .25rem;
        background: #F03C69;
        border-radius: 0;
        border: none; }
      #player #controls #progress-bar::-ms-fill-lower {
        background: #1C86F2;
        border-radius: 0; }
      #player #controls #progress-bar::-ms-fill-upper {
        background: #F03C69;
        border-radius: 0; }
      #player #controls #progress-bar::-ms-tooltip {
        display: none; }
    #player #controls #current-time {
      display: inline-block;
      margin: auto 0 auto auto; }
    #player #controls #divider {
      display: inline-block;
      margin: auto .25rem; }
    #player #controls #duration {
      display: inline-block;
      margin: auto .75rem auto 0; }
    #player #controls #play {
      cursor: pointer;
      width: 2.75rem;
      height: 2.75rem;
      line-height: 2.75rem;
      text-align: center; }
      #player #controls #play::before {
        content: '▶︎'; }
      #player #controls #play:hover {
        color: #F03C69; }
    #player #controls #pause {
      cursor: pointer;
      width: 2.75rem;
      height: 2.75rem;
      line-height: 2.75rem;
      text-align: center; }
      #player #controls #pause::before {
        content: '❚❚'; }
      #player #controls #pause:hover {
        color: #F03C69; }
